<template lang="pug">
TheModal(v-model:show="show" @update:show="$emit('update:show', $event)")
	TheBook(
		v-if="modal.step === 0"
		:title="modal.title"
		:endpoint="modal.endpoint"
		:path="modal.path"
		:left="modal.left"
		@onClick="setWorker"
		plus-hide
		custom-click
		need-search
	)
	template(v-if="modal.step === 1")
		.link.mb-4(@click="modal.step = 0") Назад
		TheList(:map="modal.posts" @onClick="createWorker")
</template>

<script>
import TheModal from '../TheModal'
import TheBook from '../TheBook'
import TheList from '../TheList'

import { computed, onMounted, reactive } from 'vue'
import { useApi } from '@/modules/api'
import BOOKS from '../../modules/constants/books'

export default {
	name: 'ModalEmployees',
	components: { TheList, TheModal, TheBook },
	props: {
		show: {
			type: Boolean,
			required: true
		},
		endpoint: {
			type: String,
			required: true
		}
	},
	emits: ['update:show', 'create'],
	setup (props, { emit }) {
		const { path, endpoint, left } = BOOKS.workers

		const modal = reactive({
			posts: {},
			step: 0,
			title: computed(() => modal.step === 0 ? 'Выберите сотрудника' : 'Выберите должность'),
			data: {},
			path,
			endpoint,
			left
		})

		onMounted(async () => {
			const { get, data } = useApi(`/${props.endpoint}/workerpost/all`)

			await get()

			data.value.content.forEach(({ id, name: left }) => {
				modal.posts[id] = { left }
			})
		})

		const setWorker = ({ id: workerId, value: { left } }) => {
			modal.data = { workerId, title: `${left}` }
			modal.step = 1
		}

		const createWorker = (postId) => {
			emit('create', { ...modal.data, postId, status: modal.posts[postId].left })
			emit('update:show', false)

			modal.step = 0
			modal.data = {}
		}

		return {
			modal,
			setWorker,
			createWorker
		}
	}
}
</script>
