import { workerSearch } from '../../../utils/workerSearch'
import { reactive } from 'vue'

export const useBasic = (additionalSettings = {}) => {
	const searchDirector = async (value) => {
		basic.director.options = {}
		basic.director.options = await workerSearch(value)
	}
	const searchFactory = (value) => {
		if (typeof additionalSettings.factorySearcher === 'function') {
			basic.factory.options = {}
			basic.factory.options = additionalSettings.factorySearcher(value)
		} else {
			console.error('additionalSettings.factorySearcher() must be passed in useBasic')
		}
	}
	const lib = {
		name: {
			placeholder: 'Наименование',
			value: '',
			required: true,
			minlength: 3
		},
		director: {
			placeholder: 'Руководитель',
			value: '',
			search: '',
			options: {},
			required: true,
			onSearch: searchDirector
		},
		phone: {
			placeholder: 'Телефон',
			value: '',
			isTelephone: true,
			/* eslint-disable-next-line */
			pattern: '^(\\+7|8|7)(\\d{10}$| \\(\\d{3}\\) \\d{3}-\\d{2}-\\d{2})'
		},
		address: {
			placeholder: 'Адрес',
			value: ''
		},
		rfid: {
			placeholder: 'RFID',
			value: '',
			required: true,
			minlength: 3
		},
		factory: {
			placeholder: 'Завод',
			value: '',
			search: '',
			options: {},
			onSearch: searchFactory,
			description: additionalSettings?.factoryDescription || null
		}
	}

	const basic = reactive({})

	const setBasic = fields => {
		Object.entries(fields).forEach(([name, item]) => {
			if (lib[name]) {
				basic[name] = lib[name]
				if ('search' in basic[name]) {
					if (item !== null) {
						basic[name].value = item.value
						basic[name].search = item.search
					}
				} else {
					basic[name].value = item
				}
			}
		})
	}

	const bodyBasic = () => {
		const obj = {}
		Object.entries(basic).forEach(([key, item]) => {
			if (['director', 'factory'].includes(key)) {
				if (item.value) {
					obj[key] = {
						id: item.value
					}
					obj[`${key}Id`] = item.value
				} else {
					obj[key] = null
				}
			} else {
				obj[key] = item.value
			}
		})
		return obj
	}

	return {
		basic,
		setBasic,
		searchDirector,
		bodyBasic
	}
}
